import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import LogoSvg from '../../../static/images/logo.svg'

const StyledSvg = styled(LogoSvg)`
  text-indent: -9999px;
  align-self: center;
  height: 33px;
`
const StyledLink = styled(Link)`
  display: flex;
`

const Logo = () => {
  return (
    <StyledLink to={`/`}>
      <StyledSvg />
    </StyledLink>
  )
}

export default Logo
