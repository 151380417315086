import React, { useState } from 'react'
import styled from '@emotion/styled'
import Logo from './Logo'
import NavList from './NavList'
import Notification from './Notification'

const Header = styled.header`
  display: flex;
  position: fixed;
  z-index: 9999;
  background: ${props => props.theme.colors.background};
  width: 100vw;
  padding: 0;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
`
const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  width: 100%;
  max-width: ${props => props.theme.sizes.maxWidth};
  margin: 0 auto;
  padding: 0 3%;
  @media (max-width: ${props => props.theme.sizes.mobileMenuWidth}) {
    padding: 0 1.5rem;
    height: 4rem;
  }
`
const Toggle = styled.div`
  display: none;
  height: 100%;
  cursor: pointer;
  z-index: 9999;
  padding-top: 2px;
  @media (max-width: ${props => props.theme.sizes.mobileMenuWidth}) {
    display: flex;
  }
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`
const Hamburger = styled.div`
  background-color: ${props => props.theme.colors.green};
  width: 22px;
  height: 2px;
  border-radius: 1px;
  transition: all 0.3s ease-out;
  align-self: center;
  position: relative;
  transform: ${props => (props.open ? 'rotate(-45deg)' : 'inherit')};
  ::before,
  ::after {
    width: 22px;
    height: 2px;
    border-radius: 1px;
    background-color: ${props => props.theme.colors.green};
    content: '';
    position: absolute;
    transition: all 0.3s ease-out;
  }
  ::before {
    transform: ${props =>
      props.open ? 'rotate(-90deg) translate(-7px, 0px)' : 'rotate(0deg)'};
    top: -7px;
  }
  ::after {
    opacity: ${props => (props.open ? '0' : '1')};
    transform: ${props => (props.open ? 'rotate(90deg) ' : 'rotate(0deg)')};
    top: 7px;
  }
`

const Menu = () => {
  const [navbarOpen, setNavbarOpen] = useState(false)
  return (
    <Header>
      <Nav>
        <Logo />
        <NavList navbarOpen={navbarOpen} />
        <Notification />
        <Toggle
          navbarOpen={navbarOpen}
          onClick={() => setNavbarOpen(!navbarOpen)}
        >
          {navbarOpen ? <Hamburger open /> : <Hamburger />}
        </Toggle>
      </Nav>
    </Header>
  )
}

export default Menu
