import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { useSiteMetadata } from '../../hooks/use-site-metadata'
import ExpandSvg from '../../../static/images/expand.svg'
import MobileNav from './MobileNav'

const StyledNavList = styled.ul`
  display: flex;
  list-style: none;
  background: ${props => props.theme.colors.background};
  height: 100%;
  margin: 0;
  padding: 0;
  justify-content: space-around;
  @media (max-width: ${props => props.theme.sizes.mobileMenuWidth}) {
    display: none;
  }
`
const NavListItem = styled.li`
  display: flex;
  flex: auto;
  align-items: center;
  padding: 1.5rem;
  height: 100%;
  position: relative;
  &:hover {
    background: ${props => props.theme.colors.grey};
  }
  &:hover > ul,
  &:focus-within > ul {
    visibility: visible;
    opacity: 1;
    display: flex;
  }
`
const SubNavList = styled.ul`
  list-style: none;
  background: ${props => props.theme.colors.grey};
  margin: 0;
  padding: 0;
  visibility: hidden;
  opacity: 0;
  display: none;
  flex-direction: column;
  position: absolute;
  max-width: 22rem;
  max-height: 16rem;
  flex-wrap: wrap;
  transition: all 0.5s ease;
  top: 4rem;
  left: 0;
  z-index: 999;
  &:hover {
    visibility: visible,
    opacity: 1,
    display: block
  }
`
const StyledExpandSvg = styled(ExpandSvg)`
  width: 0.5rem;
  height: auto;
  margin-left: 5px;
`
const SubNavListItem = styled.li`
  display: flex;
  clear: both;
  width: 12rem;
  padding: 1.5rem 0.5rem 1rem 1.5rem;
  justify-content: left;
  background: ${props => props.theme.colors.grey};
`
const SubNavLink = styled(Link)`
  color: ${props => props.theme.colors.text};
  font-weight: 500;
  font-size: 1rem;
  text-decoration: none;
  &:hover {
    color: ${props => props.theme.colors.green};
  }
`
const NavLink = styled(Link)`
  color: ${props => props.theme.colors.text};
  font-weight: 500;
  font-size: 1.1rem;
  padding-top: 1px;
  text-decoration: none;
  pointer-events: ${props => (props.emptyMenu ? 'none' : 'initial')};
  &:hover {
    color: ${props => (props.emptyMenu ? 'initial' : props.theme.colors.green)};
  }
`
const activeLinkStyle = {
  color: '#43b649',
}
const partiallyActiveLinkStyle = {
  color: '#43B649',
}

const NavList = props => {
  const { menuLinks } = useSiteMetadata()
  const { navbarOpen } = props
  return (
    <>
      <StyledNavList>
        {menuLinks.map(link => (
          <NavListItem emptyMenu={link.isEmpty} key={link.slug}>
            <NavLink
              to={link.slug}
              emptyMenu={link.isEmpty}
              aria-haspopup={link.subMenu && link.subMenu.length > 0}
              partiallyActive
              activeStyle={
                link.isEmpty ? partiallyActiveLinkStyle : activeLinkStyle
              }
            >
              {link.name}
            </NavLink>
            {link.subMenu && link.subMenu.length > 0 ? (
              <>
                <SubNavList>
                  {link.subMenu.map(subLink => (
                    <SubNavListItem key={subLink.slug}>
                      {subLink.external ? 
                        <SubNavLink
                          href={subLink.slug}
                          target="_blank"
                        >
                          {subLink.name}
                        </SubNavLink> :
                        <SubNavLink
                        to={subLink.slug}
                        activeStyle={activeLinkStyle}
                      >
                        {subLink.name}
                      </SubNavLink>
                      }
                    </SubNavListItem>
                  ))}
                </SubNavList>
                <StyledExpandSvg />
              </>
            ) : null}
          </NavListItem>
        ))}
      </StyledNavList>
      <MobileNav navbarOpen={navbarOpen} />
    </>
  )
}

export default NavList
