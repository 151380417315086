import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import MobileNavExpand from './MobileNavExpand'
import { useSiteMetadata } from '../../hooks/use-site-metadata'

const MobileNavContainer = styled.nav`
  display: flex;
  height: 100vh;
  position: fixed;
  z-index: 9998;
  width: 100%;
  justify-content: flex-start;
  padding-top: 12vh;
  background: ${props => props.theme.colors.background};
  transition: all 250ms ease-out;
  right: ${props => (props.open ? '0' : '-100%')};
  top: 0;
  touch-action: manipulation;
  -webkit-overflow-scrolling: auto;
  overflow-y: hidden;
  /* opacity: 0.95; */
  @media (min-width: ${props => props.theme.sizes.mobileMenuWidth + 1}) {
    display: none;
  }
`
const MobileNavList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 10%;
  width: 100%;
`
const MobileNavListItem = styled.li`
  display: flex;
  flex-direction: column;
  font-size: 18px;
`
const MobileNavLink = styled(Link)`
  color: ${props => props.theme.colors.text};
  font-weight: 600;
  text-decoration: none;
  padding: 0.5rem 5rem 1.5rem 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`
const activeLinkStyle = {
  color: '#43B649',
}

const MobileNav = props => {
  const { menuLinks } = useSiteMetadata()
  const { navbarOpen } = props

  useEffect(() => {
    document.body.style.overflow = navbarOpen ? 'hidden' : 'initial'
    document.body.style.minWidth = navbarOpen ? '100%' : 'initial'
  })
  return (
    <MobileNavContainer open={navbarOpen}>
      <MobileNavList>
        {menuLinks.map(link => (
          <MobileNavListItem key={link.name}>
            {link.subMenu && link.subMenu.length > 0 ? (
              <MobileNavExpand link={link} />
            ) : (
              <MobileNavLink to={link.slug} activeStyle={activeLinkStyle}>
                {link.name}
              </MobileNavLink>
            )}
          </MobileNavListItem>
        ))}
      </MobileNavList>
    </MobileNavContainer>
  )
}

export default MobileNav
