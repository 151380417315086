import { useState } from 'react'

 export const useLocalStorage = (key, defaultValue) => {
   const [storedValue, setStoredValue] = useState(() => {
     if (typeof window !== 'undefined') {
       const val = localStorage.getItem(key)
       return val ? JSON.parse(val) : defaultValue
     }
     return defaultValue
   })

   const setValue = (value) => {
     const valueToStore = value instanceof Function ? value(storedValue) : value
     setStoredValue(valueToStore)
     if (typeof window !== 'undefined') {
       localStorage.setItem(key, JSON.stringify(valueToStore))
     }
   }

   return [storedValue, setValue]
 }