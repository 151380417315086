import React, { useState, useEffect } from 'react'
import { keyframes } from '@emotion/core'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import NotificationSvg from '../../../static/images/notification.svg'
import TriUp from '../../../static/images/tri-up.svg'
import ChevronRight from '../../../static/images/chevron-right.svg'
import Mark from '../../../static/images/mark.svg'
import { useLocalStorage } from '../../storage'

function shake(props) {
  return props.read || props.open
    ? true
    : keyframes`
      /* https://codepen.io/studiotwist/pen/KmoOJw */
      0% { transform: rotate(0); }
      15% { transform: rotate(10deg); }
      30% { transform: rotate(-10deg); }
      45% { transform: rotate(7deg); }
      60% { transform: rotate(-7deg); }
      75% { transform: rotate(3deg); }
      85% { transform: rotate(-3deg); }
      92% { transform: rotate(1deg); }
      100% { transform: rotate(0); }
    `
}
const StyledSvgIcon = styled(NotificationSvg)`
  text-indent: -9999px;
  margin: 1rem 0.5rem 1rem 0;
  animation: ${props => shake(props)} 1.5s cubic-bezier(0.36, 0.07, 0.19, 0.97)
    infinite;
  &:hover,
  &:focus {
    animation: none;
    cursor: pointer;
    backface-visibility: hidden;
    transform-origin: top;
  }
`
const NotificationContainer = styled.div`
  display: flex;
  align-self: center;
  width: 3.5rem;
  height: 4.5rem;
  justify-content: flex-end;
  align-items: center;
  &::after {
    content: '';
    position: relative;
    border-radius: 50%;
    height: 9px;
    width: 9px;
    bottom: 13px;
    right: 30px;
    background-color: ${props =>
      props.read ? 'none' : props.theme.colors.lightOrange};
  }
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  @media screen and (max-width: ${props => props.theme.sizes.mobileMenuWidth}) {
    margin-left: auto;
    margin-right: -0.5rem;
  }
`
const NotifyTriUp = styled(TriUp)`
  fill-opacity: ${props => (props.open ? '1' : '0')};
  position: relative;
  top: 29px;
  right: 27px;
`
const NotifyContainer = styled.div`
  position: absolute;
  display: ${props => (props.open ? 'flex' : 'none')};
  flex-direction: column;
  width: 25%;
  min-width: 22rem;
  max-width: 25rem;
  top: 3rem;
  right: 15px;
  margin: 1em 0 3em;
  border: 1px solid #5bae46;
  box-shadow: 0px 3px 10px 3px rgba(0, 0, 0, 0.15);
  @media screen and (max-width: ${props => props.theme.sizes.mobileMenuWidth}) {
    width: 100vw;
    min-width: initial;
    right: 0;
  }
  @media screen and (min-width: 2300px) {
    right: 7%;
  }
`
const NotifyHeader = styled.div`
  display: flex;
  width: 100%;
  background: rgb(91, 174, 70);
  padding: 0.5rem;
  justify-content: center;
  font-weight: 500;
  font-size: 100%;
  color: ${props => props.theme.colors.background};
`
const NotifyHelper = styled.div`
  display: flex;
  width: 100%;
  background: ${props => props.theme.colors.lightGrey};
  justify-content: space-between;
  
`
const NotifyHelperStatus = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;
  color: ${props => props.read ? props.theme.colors.textGrey : `#d3d3d3`};
  font-size: 0.65em;
  font-weight: 500;
  line-height: 2em;
  vertical-align: middle;
`
const NotifyMark = styled(Mark)`
  display: ${props => props.read ? 'initial' : 'none' };
  width: 14px;
  margin-left: 5px;
`
const NotifyHelperButton = styled.div`
  display: flex;
  align-items: center;
  padding: 0.25rem 1rem;
  color: ${props => props.read ? props.theme.colors.textGrey : props.theme.colors.lightOrange};
  font-size: 0.8em;
  font-weight: 600;
  line-height: 1.5em;
  vertical-align: middle;
  cursor: pointer;
`
const NotifyBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${props => props.theme.colors.background};
  justify-content: center;
  font-size: 90%;
  /* Blur effect */
  opacity: 0.85;
  backdrop-filter: blur(5px);
  @media screen and (max-width: ${props => props.theme.sizes.mobileMenuWidth}) {
    opacity: 0.97;
  }
`
const NotifyItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 1rem;
  background: ${props => props.read ? props.theme.colors.lightGrey : props.theme.colors.background};
  &:last-child {
    padding-bottom: 0.5rem;
    div {
      border-bottom: none;
    }
  }
  &:hover {
    background: ${props => props.theme.colors.lightGrey};
  }
`
const NotifyItemDatestamp = styled.div`
  margin-left: 1rem;
  font-size: 75%;
  font-weight: 600;
`
const NotifyItemContent = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0.5rem 1rem 0 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #d8d8d8;
  font-size: 95%;
  font-weight: 300;
  line-height: 1.1rem;
  justify-content: space-between;
`
const NotifyItemContentText = styled.div`
  display: flex;
  word-break: keep-all;
}
`
const NotifyItemContentButton = styled(Link)`
  cursor: pointer;
  display: flex;
  align-items: center;
  min-height: 1rem;
  padding: 0 0.5rem;
  &:hover,
  &:focus {
    fill-opacity: 0.5;
  }
`
const NotifyChevronRight = styled(ChevronRight)`
  color: #5bae46;
  width: 15px;
  height: 18px;
`

const Notification = () => {
  // Open notification container UI
  const [open, setOpen] = useState(false)

  const toggleOpenMobile = e => {
    e.preventDefault()
    e.stopPropagation()
    setOpen(!open)
  }

  // Manage read state
  const [read, setRead] = useLocalStorage('tempRead1679861700', false)
  
  useEffect(() => {
    setRead(read)
  })

  const markAsRead = e => {
    return read ? false : setRead(true)
  }

  const markAsUnread = e => {
    return read ? setRead(false) : false
  }

  return (
    <>
      <NotificationContainer
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        read={read}
      >
        <StyledSvgIcon
          // onClick={markAsRead}
          onTouchStart={toggleOpenMobile}
          read={read}
          open={open}
        />
        <NotifyTriUp open={open} />
        {/* <NotifyTriangle /> */}
        <NotifyContainer open={open}>
          <NotifyHeader>{`공지 알림`}</NotifyHeader>
          <NotifyHelper>
            <NotifyHelperStatus read={read} onClick={markAsUnread}>{`UP TO DATE `}<NotifyMark read={read}/></NotifyHelperStatus>
            <NotifyHelperButton read={read} onClick={markAsRead}>다 읽음 표시</NotifyHelperButton >
          </NotifyHelper>
          <NotifyBody>
            <NotifyItemContainer read={read}>
              <NotifyItemDatestamp>{`2023.03.26`}</NotifyItemDatestamp>
              <NotifyItemContent>
                <NotifyItemContentText>
                  {`중고등부 수련회 (3/31-4/1): 문의 (May Kim 전도사, 정병일 사역 부장)`}
                  <br />{`카이로스 청년부 수련회 (4/14-16): 문의 ( 현동구 회장)`}
                </NotifyItemContentText>
              </NotifyItemContent>
            </NotifyItemContainer>
            <NotifyItemContainer read={read}>
              <NotifyItemDatestamp>{`2022.05.29`}</NotifyItemDatestamp>
              <NotifyItemContent>
                <NotifyItemContentText>
                  {`사택 주소 변경 - 우편으로 헌금이나 메일 등을 보내시는 분들은 참고하시길 바랍니다: `}<br/>
                  <br/>
                  {`5678 Bellevue Cir. Dublin, CA 94568`}
                </NotifyItemContentText>
              </NotifyItemContent>
            </NotifyItemContainer>
            {/* <NotifyItemContainer read={read}>
              <NotifyItemDatestamp>{`2022.01.14`}</NotifyItemDatestamp>
              <NotifyItemContent>
                <NotifyItemContentText>{`안내: 2022년 1월 16일 주일예배부터 이전된 주소의 교회에서 예배를 드립니다. 자세한 안내는 교회일정 페이지에서 확인 부탁드립니다. `}</NotifyItemContentText>
                <NotifyItemContentButton to={'/info/calendar/'}>
                  <NotifyChevronRight />
                </NotifyItemContentButton>
              </NotifyItemContent>
            </NotifyItemContainer>
            <NotifyItemContainer read={read}>
              <NotifyItemDatestamp>{`2022.01.14`}</NotifyItemDatestamp>
              <NotifyItemContent>
                <NotifyItemContentText>{`죠이플 교회에 오신걸 환영합니다!`}</NotifyItemContentText>
              </NotifyItemContent>
            </NotifyItemContainer> */}
          </NotifyBody>
        </NotifyContainer>
      </NotificationContainer>
    </>
  )
}

export default Notification
