import React, { useState, useRef } from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import useOnClickOuter from '../../hooks/use-on-click-outer'

const MobileNavButton = styled.button`
  color: ${props => props.theme.colors.text};
  font-weight: 600;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 0.5rem 5rem 1.5rem 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
`
const EmptyLink = styled(Link)`
  color: ${props => props.theme.colors.text};
  font-weight: 600;
  text-decoration: none;
  pointer-events: none;
`
const MobileExpandButton = styled.div`
  width: 3px;
  height: 3px;
  padding-left: 0.5rem;
  margin-right: auto;
  ::before {
    content: '';
    position: absolute;
    width: 14px;
    height: 2px;
    background: ${props => props.theme.colors.green};
    border-radius: 2px;
    display: inline-block;
  }
  ::after {
    content: '';
    position: absolute;
    margin-top: ${props => (props.minus ? '0' : '-6px')};
    margin-left: 6px;
    width: 2px;
    height: ${props => (props.minus ? '2px' : '14px')};
    background: ${props => props.theme.colors.green};
    border-radius: 2px;
    display: inline-block;
  }
  ::hover ::after {
    margin-top: 0px;
    width: 10px;
    height: 10px;
  }
  ::before,
  ::after {
    -webkit-transition: all 0.1s ease;
    -moz-transition: all 0.1s ease;
    -ms-transition: all 0.1s ease;
    -o-transition: all 0.1s ease;
    transition: all 0.1s ease;
  }
`
const MobileSubNavList = styled.ul`
  display: flex;
  list-style: none;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 12rem;
`
const MobileSubNavListItem = styled.li`
  display: flex;
  clear: both;
  padding: 0 0.7rem 1.5rem 1rem;
  justify-content: left;
  font-size: 16px;
`
const MobileSubNavLink = styled(Link)`
  color: ${props => props.theme.colors.text};
  font-weight: 600;
  text-decoration: none;
`
const activeLinkStyle = {
  color: '#43B649',
}
const partiallyActiveLinkStyle = {
  color: '#43B649',
}

const MobileNavExpand = props => {
  const { link } = props
  const [active, setActive] = useState(false)
  const [display, setdisplay] = useState('none')
  const [expanded, setExpanded] = useState(false)
  const content = useRef()
  const outer = useRef()

  const toggleExpand = () => {
    setActive(!active)
    setdisplay(active ? 'none' : 'flex')
    setExpanded(!active)
  }

  useOnClickOuter(outer, () => {
    setActive(false)
    setdisplay('none')
    setExpanded(false)
  })

  return (
    <div ref={outer}>
      <MobileNavButton id={link.name} active={active} onClick={toggleExpand}>
        <EmptyLink
          to={link.slug}
          partiallyActive
          activeStyle={partiallyActiveLinkStyle}
        >
          {link.name}
        </EmptyLink>
        <MobileExpandButton minus={expanded} />
      </MobileNavButton>
      <MobileSubNavList style={{ display: `${display}` }} ref={content}>
        {link.subMenu.map(subLink => (
          <MobileSubNavListItem key={subLink.slug}>
            <MobileSubNavLink to={subLink.slug} activeStyle={activeLinkStyle}>
              {subLink.name}
            </MobileSubNavLink>
          </MobileSubNavListItem>
        ))}
      </MobileSubNavList>
    </div>
  )
}

export default MobileNavExpand
